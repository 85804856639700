(function () {
  'use strict';

  angular
    .module('learning.moduleSet')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.moduleSet', {
        url: '/module-set',
        templateUrl: 'dashboard/learning/module-set/module-set.tpl.html',
        controller: 'ModuleSetCtrl',
        controllerAs: 'moduleSet',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
